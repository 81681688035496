<template>
    <div class="p-6">
        <ClientOnly>
            <Vueform ref="form$">
                <TextElement
                    v-if="searchEnabled"
                    name="search"
                    input-type="search"
                    label="Search filters"
                    :float-placeholders="false"
                    :floating="false"
                    placeholder="Ex. Third Row Seat"
                    :debounce="500"
                    :add-classes="{
                        TextElement: {
                            input: 'border-none input-remove-shadow',
                        },
                        ElementLabel: {
                            container: '!text-xs font-medium',
                        },
                    }"
                    @change="search = $event"
                />

                <template v-if="categoriesWithOptions.length > 0">
                    <template
                        v-for="(
                            categoryObj, parentIndex
                        ) in categoriesWithOptions"
                        :key="parentIndex"
                    >
                        <StaticElement
                            :name="`static-${parentIndex}`"
                            :conditions="[
                                () =>
                                    categoryCounts[categoryObj.category.value] >
                                    0,
                            ]"
                        >
                            <div class="font-semibold">
                                {{ categoryObj.category.value }}
                            </div>

                            <template #after>
                                <div
                                    v-if="childrenLoaded"
                                    class="mt-4 grid grid-cols-12 form-gap-x-gutter form-gap-y-gutter"
                                >
                                    <CheckboxElement
                                        v-for="(
                                            option, childIndex
                                        ) in categoryObj.options"
                                        :key="`${parentIndex}-${childIndex}`"
                                        :text="`${option.label ?? option.value}${option.count > 0 ? ` (${option.count})` : ''}`"
                                        :name="`checkbox-${parentIndex}-${childIndex}`"
                                        :default="option.checked"
                                        :conditions="[
                                            () =>
                                                !hiddenOptions.includes(
                                                    option.value,
                                                ),
                                        ]"
                                        @change="
                                            update(
                                                option,
                                                childIndex,
                                                parentIndex,
                                            )
                                        "
                                    />
                                </div>
                            </template>
                        </StaticElement>
                    </template>
                </template>
                <template v-else>
                    <CheckboxElement
                        v-for="(option, index) in options"
                        :key="index"
                        :text="`${option.label ?? option.value}${option.count > 0 ? ` (${option.count})` : ''}`"
                        :name="`checkbox-${index}`"
                        :default="option.checked"
                        :conditions="[
                            () => !hiddenOptions.includes(option.value),
                        ]"
                        @change="update(option, index)"
                    />
                </template>

                <StaticElement
                    v-if="showNoOptionsMessage"
                    name="noOptionsFound"
                >
                    No filters found.
                </StaticElement>
            </Vueform>
        </ClientOnly>
    </div>
</template>

<script setup lang="ts">
import type { PropType } from 'vue'
import type { FilterOption } from '~/utils/types/inventoryFilter'

const filterStore = useInventoryFilterStore()

const props = defineProps({
    filter: {
        type: Object as PropType<FilterOption>,
        required: true,
    },
    filtersWereReset: Boolean,
    resetFilter: {
        type: Object as PropType<Record<string, any> | null>,
        default: null,
    },
})
const emit = defineEmits(['update'])

const search = ref('')
const childrenLoaded = ref(false)

nextTick(() => (childrenLoaded.value = true))

const searchEnabled = computed(() => props.filter.search)
const categories = computed(() => props.filter.categories ?? [])
const options = computed(() => props.filter.options ?? [])

const hiddenOptions = computed(() =>
    search.value !== ''
        ? options.value
              .filter(
                  (option: any) =>
                      !option.value
                          .toLowerCase()
                          .includes(search.value.toLowerCase()),
              )
              .map((option: any) => option.value)
        : [],
)

const categoriesWithOptions = computed(() => {
    if (categories.value.length === 0) {
        return []
    }

    const returnArr: any[] = []

    JSON.parse(JSON.stringify(categories.value))
        .sort()
        .forEach((category: Record<string, any>) => {
            const filteredOptions = options.value.filter((option: any) =>
                option.value.startsWith(`${category.value} >`),
            )

            returnArr.push({
                category,
                options: filteredOptions,
            })
        })

    return returnArr
})

const categoryCounts = computed(() => {
    const returnObj: Record<string, any> = {}

    categoriesWithOptions.value.forEach((categoryObj: any) => {
        returnObj[categoryObj.category.value] = categoryObj.options.filter(
            (option: any) => !hiddenOptions.value.includes(option.value),
        ).length
    })

    return returnObj
})

const showNoOptionsMessage = computed(
    () =>
        search.value !== '' &&
        options.value.length === hiddenOptions.value.length,
)

const form$ = ref()

watch(
    () => filterStore.filtersResetting,
    (shouldReset) => {
        if (shouldReset) {
            form$.value.clear()
        }
    },
)

watch(
    () => filterStore.filtersComponentFullResetId,
    (id) => {
        if (id !== null && id === props.filter.id) {
            form$.value.clear()
        }
    },
)

watch(
    () => props.resetFilter,
    (filter) => {
        if (filter !== null && props.filter.id === filter.filter) {
            resetSingleValue(filter)
        }
    },
)

function update(
    option: Record<string, string>,
    childIndex: number,
    parentIndex: number | null = null,
) {
    if (!filterStore.filtersResetting) {
        const checkbox = form$.value.el$(
            parentIndex !== null
                ? `checkbox-${parentIndex}-${childIndex}`
                : `checkbox-${childIndex}`,
        )

        emit('update', {
            filter: props.filter.id,
            title: props.filter.name,
            type: props.filter.type,
            option: option.value,
            value: checkbox.value,
            selectType: props.filter.selectType,
        })
    }
}

function resetSingleValue(removeOption: Record<string, any>) {
    if (categoriesWithOptions.value.length === 0) {
        const options = props.filter.options ?? []
        const index = options.findIndex(
            (option: any) => option.value === removeOption.option,
        )

        form$.value.el$(`checkbox-${index}`)?.clear()
    } else {
        categoriesWithOptions.value.forEach(
            (categoryObj: any, parentIndex: number) => {
                categoryObj.options.forEach(
                    (option: any, childIndex: number) => {
                        if (option.value === removeOption.option) {
                            form$.value
                                .el$(`checkbox-${parentIndex}-${childIndex}`)
                                ?.clear()
                        }
                    },
                )
            },
        )
    }
}
</script>
