<template>
    <div
        class="-mx-1 px-6 py-6 flow-root cursor-pointer hover:bg-gray-200"
        :class="{ 'shadow-md': open }"
    >
        <div class="px-1 flex w-full items-center justify-between text-base">
            <span class="font-medium flex">
                {{ name }}
                <span v-if="highlight" class="text-sm font-light ml-2">
                    {{ highlight }}
                </span>
                <span
                    v-if="parsedCount > 0"
                    class="flex items-center justify-center w-6 h-6 rounded ml-2 text-md font-bold bg-gray-700 text-white"
                >
                    {{ parsedCount }}
                </span>
            </span>
            <span class="ml-6 flex items-center">
                <button
                    v-if="parsedCount > 0"
                    type="button"
                    class="mr-2 rounded-full px-2.5 py-1 text-xs font-medium text-gray-900 hover:bg-gray-300"
                    @click.stop="emit('clear')"
                >
                    Clear
                </button>

                <ChevronDownIcon
                    v-if="!open"
                    class="h-5 w-5"
                    aria-hidden="true"
                />
                <ChevronUpIcon v-else class="h-5 w-5" aria-hidden="true" />
            </span>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/vue/24/solid'
import type { PropType } from 'vue'

const props = defineProps({
    name: String,
    open: Boolean,
    count: Number,
    highlight: {
        type: [String, null] as PropType<string | undefined>,
        default: undefined,
    },
})

const emit = defineEmits(['clear'])

const parsedCount = computed(() => props.count ?? 0)
</script>
