<template>
    <div
        v-if="!filtersSyncing"
        :class="[isMobile ? 'block' : 'hidden lg:block']"
    >
        <div class="relative lg:h-full lg:min-h-screen lg:mr-8">
            <div
                class="lg:my-8 lg:sticky lg:top-8 lg:flex lg:flex-col lg:gap-6"
            >
                <div class="hidden lg:block">
                    <SearchModeToggle v-if="!searchStore.getIsAiMode" />
                </div>
                <!-- lg:overflow-y-auto -->
                <!-- shadow shows through w getIsAiMode -->
                <div
                    v-if="!searchStore.getIsAiMode"
                    v-auto-animate
                    class="bg-white shadow-lg lg:z-30 lg:w-80 divide-y overflow-x-hidden divide-gray-200 border border-gray-200 rounded-xl max-h-screen"
                >
                    <div
                        v-if="filterCounts.total > 0 || pills.length > 0"
                        class="bg-gray-100 p-6"
                    >
                        <div class="flex justify-between items-center mb-2">
                            <div class="font-semibold flex items-center">
                                Filters
                                <span
                                    v-if="filterCounts.total > 0"
                                    class="flex items-center justify-center w-6 h-6 rounded ml-2 text-md font-bold bg-gray-700 text-white"
                                >
                                    {{ filterCounts.total }}
                                </span>
                            </div>
                            <button
                                class="text-xs cursor-pointer underline underline-offset-4"
                                @click="emit('resetFilters')"
                            >
                                {{ t('clear_all') }}
                            </button>
                        </div>
                        <div class="flex flex-wrap">
                            <Pills
                                :pills="pills"
                                @remove-filter="emit('removeFilter', $event)"
                            />
                        </div>
                    </div>

                    <div v-if="isMobile" v-auto-animate>
                        <FilterButton
                            name="Sort by"
                            :highlight="currentSort.name"
                            :open="sortFilterOpen"
                            @click="toggle('sort')"
                        />

                        <MobileSort v-if="sortFilterOpen" />
                    </div>

                    <div v-if="!locationFilter.shouldHide" v-auto-animate>
                        <FilterButton
                            :name="locationFilter.name"
                            :open="locationFilterOpen"
                            :count="filterCounts[locationFilter.id] ?? 0"
                            @click="toggle('location')"
                            @clear="clearEntireFilterCategory('location')"
                        />

                        <component
                            :is="locationFilter.component"
                            v-if="locationFilterOpen"
                            :filter="locationFilter"
                            :reset-filter="resetFilter"
                            @update="emit('update', $event)"
                        />
                    </div>

                    <template
                        v-for="(filter, index) in otherFilters"
                        :key="filter.id"
                    >
                        <div
                            v-if="
                                !filter.shouldHide &&
                                (filter.type !== 'facet' ||
                                    filter.options !== undefined)
                            "
                            v-auto-animate
                        >
                            <FilterButton
                                :name="filter.name"
                                :open="otherFiltersOpenArr[index]"
                                :count="filterCounts[filter.id] ?? 0"
                                @click="toggle(index)"
                                @clear="clearEntireFilterCategory(index)"
                            />

                            <component
                                :is="filter.component"
                                v-if="otherFiltersOpenArr[index]"
                                :filter="filter"
                                :reset-filter="resetFilter"
                                @update="emit('update', $event)"
                            />
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import type { PropType } from 'vue'
import type { FilterOption } from '~/utils/types/inventoryFilter'
import FilterButton from '~/components/SearchResultsPage/SearchResultsFilter/FilterButton.vue'
import Pills from '~/components/SearchResultsPage/SearchResultsFilter/Pills.vue'
import MobileSort from '~/components/SearchResultsPage/SearchResultsFilter/MobileSort.vue'
import SearchModeToggle from '~/components/SearchResultsPage/SearchResultsFilter/SearchModeToggle.vue'

const inventoryStore = useInventoryStore()
const filterStore = useInventoryFilterStore()
const searchStore = useSearchStore()

const props = defineProps({
    filters: {
        type: Array as PropType<FilterOption[]>,
        default: () => [],
    },
    pills: {
        type: Array as PropType<Pill[]>,
        default: () => [],
    },
    filterCounts: {
        type: Object,
        default: () => {},
    },
    query: {
        type: Object as PropType<Record<string, any>>,
        default: () => {},
    },
    resetFilter: {
        type: Object as PropType<Record<string, any> | null>,
        default: null,
    },
    isMobile: {
        type: Boolean,
        default: false,
    },
})

const emit = defineEmits([
    'update',
    'resetFilters',
    'removeFilter',
    'resetFiltersInCategory',
])

const { t } = useI18n()

const filtersSyncing = computed(() => filterStore.filtersSyncing)
const locationFilter = computed(() => props.filters[0])
const otherFilters = computed(() =>
    locationFilter.value.shouldHide ? props.filters : props.filters.slice(1),
)

const locationFilterOpen = ref(
    !!props.query['location_search_term.lvl0'] ||
        !!props.query['location_search_term.lvl1'] ||
        filterStore.filtersComponentFullResetId === 'location',
)
const sortFilterOpen = ref(false)
const otherFiltersOpenArr = ref([] as boolean[])

const currentSort = computed(() => inventoryStore.getCurrentSortOption)

// for mobile, we have to assign the initial open filters on load
if (props.isMobile && otherFiltersOpenArr.value.length === 0) {
    otherFiltersOpenArr.value = getInitialOpenFilters()
}

// wait for facets to load to open potential attribute filters
watch(
    () => props.filters,
    () => {
        if (otherFiltersOpenArr.value.length === 0) {
            otherFiltersOpenArr.value = getInitialOpenFilters()
        }
    },
)

// if the reset filter is closed, we have to manually update the facet here
watch(
    () => props.resetFilter,
    (removeFilter) => {
        if (removeFilter !== null) {
            if (removeFilter.type === 'query') {
                emit('update', {
                    filter: removeFilter.filter,
                    type: removeFilter.type,
                    option: removeFilter.option,
                    value: null,
                })
            } else {
                let attributeCategory: string | null = null

                if (removeFilter.filter.toString().startsWith('attribute.')) {
                    const attributeNames = getAttributeNames(
                        removeFilter.filter.toString(),
                    )

                    attributeCategory = attributeNames.category
                }

                // get the index of the reset filter
                const index = (props.filters ?? []).findIndex(
                    (option: FilterOption) =>
                        attributeCategory
                            ? option.id === attributeCategory
                            : removeFilter.range
                              ? option.id === removeFilter.range
                              : option.id === removeFilter.filter ||
                                option.childId === removeFilter.filter,
                )

                if (index > -1) {
                    const filterObj = props.filters[index]

                    const isClosed =
                        index === 0
                            ? !locationFilterOpen.value
                            : !otherFiltersOpenArr.value[index]

                    if (isClosed) {
                        if (filterObj.type === 'facet') {
                            if (attributeCategory) {
                                emit('update', {
                                    filter: removeFilter.filter,
                                    type: filterObj.type,
                                    option: removeFilter.option,
                                    value: false,
                                })
                            } else if (filterObj.childId !== undefined) {
                                const isChild =
                                    filterObj.childId === removeFilter.filter

                                if (isChild) {
                                    emit('update', {
                                        filter: filterObj.childId,
                                        option: removeFilter.option,
                                        value: false,
                                        type: filterObj.type,
                                    })
                                } else {
                                    emit('update', {
                                        filter: filterObj.id,
                                        option: removeFilter.option,
                                        value: false,
                                        type: filterObj.type,
                                        clearChildren: {
                                            key: filterObj.childId,
                                            parent: removeFilter.option,
                                        },
                                    })
                                }
                            } else {
                                emit('update', {
                                    filter: filterObj.id,
                                    type: filterObj.type,
                                    option: removeFilter.option,
                                    value: false,
                                    selectType: filterObj.selectType,
                                })
                            }
                        } else {
                            emit('update', {
                                filter: filterObj.id,
                                type: filterObj.type,
                                value: null,
                            })
                        }
                    }
                }
            }
        }
    },
)

function toggle(index: string | number) {
    index = index.toString()

    if (index === 'location') {
        locationFilterOpen.value = !locationFilterOpen.value
    } else if (index === 'sort') {
        sortFilterOpen.value = !sortFilterOpen.value
    } else {
        index = parseInt(index)
        otherFiltersOpenArr.value[index] = !otherFiltersOpenArr.value[index]
    }
}

function clearEntireFilterCategory(index: string | number) {
    const originalIndex = index.toString()
    let filter: FilterOption | null = null

    if (originalIndex === 'location') {
        index = 0
        filter = locationFilter.value
    } else {
        index = parseInt(originalIndex)
        filter = otherFilters.value[index] ?? null
    }

    if (filter) {
        let buttonWasOpenOnClick: boolean

        if (originalIndex === 'location') {
            buttonWasOpenOnClick = locationFilterOpen.value

            if (!buttonWasOpenOnClick) {
                locationFilterOpen.value = true
            }
        } else {
            buttonWasOpenOnClick = otherFiltersOpenArr.value[index]

            if (!buttonWasOpenOnClick) {
                otherFiltersOpenArr.value[index] = true
            }
        }

        nextTick(() => {
            filterStore.filtersComponentFullResetId = filter.id

            nextTick(() => {
                filterStore.filtersComponentFullResetId = null

                if (!buttonWasOpenOnClick) {
                    if (originalIndex === 'location') {
                        locationFilterOpen.value = false
                    } else {
                        otherFiltersOpenArr.value[index] = false
                    }
                }
            })
        })
    }
}

function getInitialOpenFilters() {
    return otherFilters.value.map((filter) => filter.open)
}

function getAttributeNames(key: string) {
    const keySplit = key.replace('attribute.', '').split('.')

    return {
        category: keySplit[0],
        class: keySplit[1],
    }
}
</script>
