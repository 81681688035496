<template>
    <ClientOnly>
        <div
            class="mt-8 flex flex-col space-y-2 text-xs text-gray-500 max-w-wide"
        >
            <div>
                {{
                    parseLocaleTemplates(t('srp_disclaimer_1'), {
                        creditScoreRangeFormatted: creditScoreRangeFormatted,
                        downPaymentPercent: downPaymentPercent,
                    })
                }}
            </div>
            <div>{{ t('srp_disclaimer_2') }}</div>
        </div>
    </ClientOnly>
</template>

<script setup lang="ts">
import { parseLocaleTemplates } from '~/utils/functions'

const domainStore = useDomainStore()
const paymentCalculatorStore = usePaymentCalculatorStore()

const { t } = useI18n()

const downPaymentPercent = computed(
    () => domainStore.payments.defaults.downPercentage ?? 10,
)

const creditScoreRangeFormatted = computed(
    () => paymentCalculatorStore.currentRangeCreditScoreFormatted,
)
</script>
