<template>
    <div class="px-6 pb-6">
        <ClientOnly>
            <template
                v-for="(category, parentIndex) in options"
                :key="parentIndex"
            >
                <Vueform
                    :ref="
                        (el: any) => {
                            formRefs[parentIndex] = el
                        }
                    "
                >
                    <StaticElement :name="`static-${parentIndex}`">
                        <div class="font-semibold mt-6">
                            {{ category.class }}
                        </div>

                        <template #after>
                            <div
                                v-if="childrenLoaded"
                                class="mt-4 grid grid-cols-12 form-gap-x-gutter form-gap-y-gutter"
                            >
                                <CheckboxElement
                                    v-for="(
                                        child, childIndex
                                    ) in category.values"
                                    :key="`${parentIndex}-${childIndex}`"
                                    :text="`${child.label ?? child.value}${child.count > 0 ? ` (${child.count})` : ''}`"
                                    :name="`${parentIndex}-${childIndex}`"
                                    :default="child.checked"
                                    @change="
                                        update(
                                            category,
                                            child,
                                            parentIndex,
                                            `${parentIndex}-${childIndex}`,
                                        )
                                    "
                                />
                            </div>
                        </template>
                    </StaticElement>
                </Vueform>
            </template>

            <!--            <div class="space-y-4 sm:space-y-3"> -->
            <!--                <div v-for="(category, index) in options" :key="index"> -->
            <!--                    <div class="flex items-center"> -->
            <!--                        <label class="ml-3 text-base"> -->
            <!--                            {{ category.class }} -->
            <!--                        </label> -->
            <!--                    </div> -->

            <!--                    <div class="ml-6 mt-4 space-y-4 sm:space-y-3"> -->
            <!--                        <Vueform -->
            <!--                            :ref=" -->
            <!--                                (el: any) => { -->
            <!--                                    formRefs[index] = el -->
            <!--                                } -->
            <!--                            " -->
            <!--                        > -->
            <!--                            <CheckboxElement -->
            <!--                                v-for="(child, childIndex) in category.values" -->
            <!--                                :key="`${index}-${childIndex}`" -->
            <!--                                :text="`${child.label ?? child.value}${child.count > 0 ? ` (${child.count})` : ''}`" -->
            <!--                                :name="`${index}-${childIndex}`" -->
            <!--                                :default="child.checked" -->
            <!--                                @change=" -->
            <!--                                    update( -->
            <!--                                        category, -->
            <!--                                        child, -->
            <!--                                        index, -->
            <!--                                        `${index}-${childIndex}`, -->
            <!--                                    ) -->
            <!--                                " -->
            <!--                            /> -->
            <!--                        </Vueform> -->
            <!--                    </div> -->
            <!--                </div> -->
            <!--            </div> -->
        </ClientOnly>
    </div>
</template>

<script setup lang="ts">
import type { PropType } from 'vue'
import type { FilterOption } from '~/utils/types/inventoryFilter'

const filterStore = useInventoryFilterStore()

const props = defineProps({
    filter: {
        type: Object as PropType<FilterOption>,
        default: () => {},
    },
    resetFilter: {
        type: Object as PropType<Record<string, any> | null>,
        default: null,
    },
})
const emit = defineEmits(['update'])

const filtersResetting = computed(() => filterStore.filtersResetting)
const formRefs = ref(new Array((props.filter.options ?? []).length))
const options = computed(() => props.filter.options ?? [])

const childrenLoaded = ref(false)

nextTick(() => (childrenLoaded.value = true))

watch(
    () => filtersResetting.value,
    (shouldReset) => {
        if (shouldReset) {
            resetCheckboxes()
        }
    },
)

watch(
    () => filterStore.filtersComponentFullResetId,
    (id) => {
        if (id !== null && id === props.filter.id) {
            resetCheckboxes()
        }
    },
)

watch(
    () => props.resetFilter,
    (filter) => {
        if (filter !== null && props.filter.id === filter.category) {
            resetSingleValue(filter)
        }
    },
)

function resetCheckboxes() {
    for (let i = 0; i < options.value.length; i++) {
        formRefs.value[i].clear()
    }
}

function update(
    category: Record<string, any>,
    child: Record<string, any>,
    formIndex: number,
    checkBoxIndex: string,
) {
    if (!filtersResetting.value) {
        const checkbox = formRefs.value[formIndex].el$(checkBoxIndex)

        emit('update', {
            filter: category.key,
            title: props.filter.name,
            type: props.filter.type,
            option: child.value,
            value: checkbox.value,
        })
    }
}

function resetSingleValue(removeOption: Record<string, any>) {
    const options = props.filter.options ?? []
    const formIndex = options.findIndex(
        (option: any) => option.key === removeOption.filter,
    )
    const childIndex = options[formIndex]?.values.findIndex(
        (value: any) => value.value === removeOption.option,
    )

    formRefs.value[formIndex].el$(`${formIndex}-${childIndex}`)?.uncheck()
}
</script>
