<template>
    <div class="flex lg:px-0 lg:pt-4">
        <h1 class="hidden text-4xl font-bold tracking-tight text-gray-900">
            {{ t('inventory') }}
        </h1>

        <div
            class="relative text-gray-600 w-full rounded-xl shadow hover:shadow-lg lg:w-1/2"
        >
            <MagnifyingGlassIcon
                v-if="!showLoader"
                class="pointer-events-none absolute left-4 top-5 size-5 text-gray-400"
                aria-hidden="true"
            />
            <svg
                v-else
                class="animate-spin pointer-events-none absolute left-4 top-5 size-5 text-gray-400"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
            >
                <circle
                    class="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    stroke-width="4"
                />
                <path
                    class="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                />
            </svg>
            <input
                ref="searchRef"
                v-model="search"
                class="pl-11 border-1 border-gray-200 bg-white px-5 pr-8 sm:pr-16 rounded-xl text-sm focus:outline-none w-full h-16"
                type="search"
                name="search"
                :placeholder="
                    !totalResults || totalResults === 0
                        ? t('search')
                        : parseLocaleTemplates(
                              t('search_standard_placeholder'),
                              {
                                  pluralVehicles: pluralVehicles.toLowerCase(),
                              },
                          )
                "
                @keydown="isTyping = true"
            />
        </div>
        <SearchResultsSortButton :query="query" />
        <!--        <p-->
        <!--            v-if="totalResults !== null"-->
        <!--            class="my-2 text-gray-700 text-sm font-medium"-->
        <!--        >-->
        <!--            {{ pluralVehicles }}-->
        <!--        </p>-->
    </div>

    <!--    <div class="flex items-center sm:flex-none px-4 lg:px-0">-->
    <!--        <h1-->
    <!--            class="text-base sm:text-2xl font-semibold tracking-tight text-gray-900"-->
    <!--        >-->
    <!--            {{ totalResults }} {{ t('used_cars_trucks_suvs') }} for sale in-->
    <!--            {{ currentLocation?.address.city }},-->
    <!--            {{ currentLocation?.address.state }}-->
    <!--        </h1>-->
    <!--        <span-->
    <!--            v-if="totalResults !== null"-->
    <!--            class="sm:my-2 text-gray-700 text-sm font-medium"-->
    <!--        >-->
    <!--            {{ pluralize(totalResults, t('vehicle')) }}-->
    <!--        </span>-->
    <!--    </div>-->
</template>

<script setup lang="ts">
import { type PropType, ref } from 'vue'
import { MagnifyingGlassIcon } from '@heroicons/vue/20/solid'
import { parseLocaleTemplates, pluralize } from '~/utils/functions'
import SearchResultsSortButton from '~/components/SearchResultsPage/SearchResultsHeading/SearchResultsSortButton.vue'

const route = useRoute()
const inventoryStore = useInventoryStore()
const filterStore = useInventoryFilterStore()

const props = defineProps({
    totalResults: {
        type: [Number, null] as PropType<number | null>,
        required: true,
    },
    query: {
        type: Object as PropType<Record<string, string | number>>,
        default: () => {},
    },
})

const emit = defineEmits(['queryUpdated'])

const search = ref(route.query.query ?? '')
const searchRef = ref()
const queryChanged = ref<boolean>(false)
const isTyping = ref<boolean>(false)

watch(
    () => inventoryStore.inventoryLoading,
    (loading: boolean) => {
        if (!loading) {
            queryChanged.value = false

            if (!currentQuery.value) {
                search.value = ''
            }
        }
    },
)

watch(
    () => search.value,
    () => (queryChanged.value = true),
)

const { t } = useI18n()

const currentQuery = computed(() => {
    const query = filterStore.customQueryFilters?.query || []
    return query[0] || null
})

const pluralVehicles = computed(() =>
    pluralize(props.totalResults ?? 0, t('vehicle')),
)

const showLoader = computed(
    () =>
        queryChanged.value &&
        (isTyping.value || inventoryStore.inventoryLoading),
)

watchDebounced(
    () => search.value,
    () => {
        if (search.value !== '' || currentQuery.value !== null) {
            isTyping.value = false
            emit('queryUpdated', search.value)
        }
    },
    { debounce: 750, maxWait: 5000 },
)

// const currentLocation = computed(() => locationStore.currentLocation)
</script>
