<template>
    <div v-auto-animate class="pt-4 md:pt-0 flex flex-col space-y-4 w-full">
        <div class="lg:hidden">
            <SearchModeToggle />
        </div>
        <SearchResultsAiHeading
            v-if="searchStore.getIsAiMode"
            :total-results="totalResults"
        />
        <SearchResultsStandardHeading
            v-else
            :query="query"
            :total-results="totalResults"
            @query-updated="emit('queryUpdated', $event)"
        />

        <!--        <SearchResultsNavigation-->
        <!--            :query="query"-->
        <!--            :pills="pills"-->
        <!--            @remove-filter="emit('removeFilter', $event)"-->
        <!--        />-->
        <div
            class="w-full flex-1 lg:px-0 grid grid-cols-1 gap-y-4 sm:grid-cols-2 sm:gap-x-4 sm:gap-y-6 lg:grid-cols-3 lg:gap-x-6"
        >
            <!-- Loading Skeleton while API Query is happening -->
            <template v-if="inventoryLoading">
                <VehicleSkeleton v-for="index in 12" :key="index" />
            </template>

            <div
                v-for="(vehicleEntity, index) in inventoryWithBanners"
                :key="index"
            >
                <GridVehicle
                    v-if="vehicleEntity.type === 'vehicle'"
                    :vehicle="vehicleEntity.entity"
                    :position="vehicleEntity.position"
                    :srp="true"
                    :intersected="
                        currentIntersectedVehicleId === vehicleEntity.entity.id
                    "
                    @intersected="updateIntersected"
                    @unintersected="checkUnintersected"
                />

                <GridBanner v-else :banner="vehicleEntity.entity" />
            </div>
        </div>

        <EmptyResults
            v-if="!inventoryLoading && inventoryWithBanners.length === 0"
        />
    </div>
</template>

<script setup lang="ts">
import type { PropType } from 'vue'
import type {
    Category,
    InventoryMerchandising,
    Vehicle,
} from '~/utils/types/inventory'
import GridVehicle from '~/components/SearchResultsPage/Vehicle/GridVehicle/GridVehicle.vue'
import GridBanner from '~/components/SearchResultsPage/Vehicle/GridBanner.vue'
import VehicleSkeleton from '~/components/SearchResultsPage/Vehicle/GridVehicle/GridVehicleSkeleton.vue'
import EmptyResults from '~/components/SearchResultsPage/EmptyResults.vue'
import SearchResultsAiHeading from '~/components/SearchResultsPage/SearchResultsHeading/SearchResultsAiHeading.vue'
import SearchResultsStandardHeading from '~/components/SearchResultsPage/SearchResultsHeading/SearchResultsStandardHeading.vue'
import type { ResourceMeta } from '~/utils/types/global'
import SearchModeToggle from '~/components/SearchResultsPage/SearchResultsFilter/SearchModeToggle.vue'

const inventoryStore = useInventoryStore()
const searchStore = useSearchStore()

const props = defineProps({
    inventory: {
        type: Array as PropType<Vehicle[]>,
        default: () => [],
    },
    meta: {
        type: Object as PropType<ResourceMeta>,
        required: true,
    },
    pills: {
        type: Array as PropType<Pill[]>,
        default: () => [],
    },
    query: {
        type: Object as PropType<Record<string, string | number>>,
        default: () => {},
    },
    category: {
        type: Object as PropType<Category | null>,
        default: null,
    },
    merchandising: {
        type: Object as PropType<InventoryMerchandising | null>,
        default: null,
    },
    defaultBanners: {
        type: Array as PropType<any>,
        default: () => [],
    },
    isFirstPage: {
        type: Boolean,
        default: false,
    },
})

const emit = defineEmits(['queryUpdated', 'removeFilter'])

const currentIntersectedVehicleId = ref<number | null>(null)

const totalResults = computed(() => props.meta?.total ?? null)

const inventoryLoading = computed(
    () => inventoryStore.firstLoad || inventoryStore.inventoryLoading,
)

const banners = computed(() => {
    const banners = props.merchandising?.banners ?? props.defaultBanners ?? []
    return banners.slice(0, 3)
})

const vehicleBannerModulus = 4

const inventoryWithBanners = computed(() => {
    if (
        !props.isFirstPage ||
        banners.value.length === 0 ||
        props.inventory.length < vehicleBannerModulus
    ) {
        return props.inventory.map((vehicle, index) => ({
            type: 'vehicle',
            entity: vehicle,
            position: getVehiclePosition(index + 1),
        }))
    }

    const returnArr = []
    let currentBannerIndex = 0

    for (let i = 0; i < props.inventory.length; i++) {
        if (i > 0 && i % vehicleBannerModulus === 0) {
            const banner = banners.value[currentBannerIndex]

            if (banner) {
                returnArr.push({
                    type: 'banner',
                    entity: banner,
                })

                currentBannerIndex++
            }
        }

        returnArr.push({
            type: 'vehicle',
            entity: props.inventory[i],
            position: getVehiclePosition(i + 1),
        })
    }

    return returnArr
})

function getVehiclePosition(positionOnPage: number) {
    return positionOnPage + (props.meta.current_page - 1) * props.meta.per_page
}

function updateIntersected(vehicleId: number) {
    currentIntersectedVehicleId.value = vehicleId
}

function checkUnintersected(vehicleId: number) {
    if (currentIntersectedVehicleId.value === vehicleId) {
        currentIntersectedVehicleId.value = null
    }
}
</script>
