<template>
    <div class="p-6">
        <ClientOnly>
            <Vueform ref="parentForm$">
                <CheckboxElement
                    v-for="(option, index) in optionsFiltered"
                    :key="index"
                    :text="`${option.label ?? option.value}${option.count > 0 ? ` (${option.count})` : ''}`"
                    :name="`checkbox-${index}`"
                    :default="option.checked"
                    @change="update(option, `checkbox-${index}`)"
                >
                    <template #after>
                        <div v-auto-animate>
                            <div
                                v-if="option.checked"
                                class="ml-6 mt-4 grid grid-cols-12 form-gap-x-gutter form-gap-y-gutter"
                            >
                                <CheckboxElement
                                    v-for="(
                                        child, childIndex
                                    ) in option.children"
                                    :key="child.value"
                                    :text="`${
                                        filter.childFormat
                                            ? filter.childFormat(child.child)
                                            : child.child
                                    }${child.count > 0 ? ` (${child.count})` : ''}`"
                                    :name="`checkbox-${index}-${childIndex}`"
                                    :default="child.checked"
                                    :add-class="{
                                        label: 'mt-2',
                                    }"
                                    @change="
                                        update(
                                            child,
                                            `checkbox-${index}-${childIndex}`,
                                            true,
                                        )
                                    "
                                />
                            </div>
                        </div>
                    </template>
                </CheckboxElement>
            </Vueform>
        </ClientOnly>

        <div
            v-if="
                filter.showMoreAmount !== undefined &&
                options.length > filter.showMoreAmount
            "
            class="mt-4 cursor-pointer text-center font-medium"
            @click="showMore = !showMore"
        >
            {{ shouldShowMore ? 'Show Less' : 'Show More' }}
        </div>
    </div>
</template>

<script setup lang="ts">
import type { PropType } from 'vue'
import type { Facet, FilterOption } from '~/utils/types/inventoryFilter'

const filterStore = useInventoryFilterStore()

const props = defineProps({
    filter: {
        type: Object as PropType<FilterOption>,
        default: () => {},
    },
    resetFilter: {
        type: Object as PropType<Record<string, any> | null>,
        default: null,
    },
})
const emit = defineEmits(['update'])

const options = computed(() => {
    return props.filter.options || []
})

const facetChildren = computed(() => props.filter.children || [])

const shouldShowMore = computed(() => {
    return (
        (props.filter.showMoreAmount &&
            options.value.length <= props.filter.showMoreAmount) ||
        showMore.value
    )
})

const extraOptions = computed(() => {
    if (shouldShowMore.value) {
        return []
    }

    return options.value.filter(
        (option: Facet, index: number) =>
            props.filter.showMoreAmount &&
            index + 1 > props.filter.showMoreAmount &&
            option.checked,
    )
})

const optionsWithChildren = computed(() => {
    return addChildrenToParents(options.value)
})

const optionsFiltered = computed(() => {
    if (!optionsWithChildren.value) {
        return []
    } else if (shouldShowMore.value) {
        return optionsWithChildren.value
    }

    return [
        ...optionsWithChildren.value.slice(0, props.filter.showMoreAmount),
        ...extraOptions.value,
    ]
})

const filtersResetting = computed(() => filterStore.filtersResetting)
const showMore = ref(false)
const disableUpdating = ref(false)
const parentForm$ = ref()

watch(
    () => filtersResetting.value,
    (shouldReset) => {
        if (shouldReset) {
            parentForm$.value.clear()
        }
    },
)

watch(
    () => filterStore.filtersComponentFullResetId,
    (id) => {
        if (id !== null && id === props.filter.id) {
            parentForm$.value.clear()
        }
    },
)

watch(
    () => props.resetFilter,
    (filter) => {
        if (
            filter !== null &&
            (props.filter.id === filter.filter ||
                props.filter.childId === filter.filter)
        ) {
            resetSingleValue(filter)
        }
    },
)

watch(
    () => showMore.value,
    () => rebuildCheckboxes(),
)

// revalidate checkboxes are correct after potential array changes with show more/less
function rebuildCheckboxes() {
    const options = optionsFiltered.value ?? []

    disableUpdating.value = true

    for (let i = 0; i < options.length; i++) {
        const checkbox = parentForm$.value.el$(`checkbox-${i}`)
        const option = options[i]

        if (checkbox) {
            if (option.checked) {
                checkbox.check()
            } else {
                checkbox.uncheck()
            }
        }
    }

    nextTick(() => (disableUpdating.value = false))
}

function addChildrenToParents(parentRef: any[]) {
    const parents = [...parentRef]
    const children = [...facetChildren.value]

    return parents.map((parent) => {
        parent.children = children.filter(
            (child) => parent.value === child.parent,
        )

        return parent
    })
}

function update(
    option: Record<string, string>,
    checkboxName: string,
    isChild: boolean = false,
) {
    if (!filtersResetting.value && !disableUpdating.value) {
        const checkbox = parentForm$.value.el$(checkboxName)

        if (isChild) {
            emit('update', {
                filter: props.filter.childId,
                title: props.filter.name,
                option: option.value,
                value: checkbox.value,
                type: props.filter.type,
            })
        } else {
            emit('update', {
                filter: props.filter.id,
                title: props.filter.name,
                option: option.value,
                value: checkbox.value,
                type: props.filter.type,
                clearChildren: !checkbox.value
                    ? {
                          key: props.filter.childId,
                          parent: option.value,
                      }
                    : null,
            })
        }
    }
}

function resetSingleValue(removeOption: Record<string, any>) {
    const options = optionsFiltered.value ?? []
    const isChild = props.filter.childId === removeOption.filter
    let checkbox

    if (isChild) {
        const parentChildArr: string[] = removeOption.option.split(' > ')
        const parent = parentChildArr[0]

        const parentIndex = options.findIndex(
            (option: any) => option.value === parent,
        )
        const childIndex = options[parentIndex]?.children.findIndex(
            (option: any) => option.value === removeOption.option,
        )

        checkbox = `checkbox-${parentIndex}-${childIndex}`
    } else {
        const index = options.findIndex(
            (option: any) => option.value === removeOption.option,
        )

        checkbox = `checkbox-${index}`
    }

    parentForm$.value.el$(checkbox)?.uncheck()
}
</script>
