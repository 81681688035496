<template>
    <Menu as="div" class="relative items-center flex flex-shrink-0 ml-2">
        <div class="flex">
            <MenuButton
                class="hidden lg:inline-flex group justify-center text-sm font-medium hover:bg-gray-200 rounded-lg p-3 text-gray-700"
            >
                <Bars3BottomLeftIcon
                    class="mr-2 h-5 w-5 flex-shrink-0 hover:text-gray-900"
                    aria-hidden="true"
                />
                <span class="lg:hidden hover:text-gray-900">
                    {{ t('sort') }}
                </span>
                <span class="hidden lg:block hover:text-gray-900">
                    {{ t('sort_by') }} {{ currentSort.name }}
                </span>
            </MenuButton>
            <button
                class="group inline-flex lg:hidden justify-center text-sm font-medium hover:bg-gray-200 rounded-lg p-3 text-gray-700"
                @click="inventoryFilterStore.toggleMobileFilter"
            >
                <AdjustmentsHorizontalIcon
                    class="mr-2 h-5 w-5 flex-shrink-0 hover:text-gray-900"
                    aria-hidden="true"
                />
                <span class="lg:hidden hover:text-gray-900">
                    {{ t('filters') }}
                </span>
                <span class="hidden lg:block hover:text-gray-900">
                    {{ t('sort_by') }} {{ currentSort.name }}
                </span>
            </button>
        </div>

        <transition
            enter-active-class="transition ease-out duration-100"
            enter-from-class="transform opacity-0 scale-95"
            enter-to-class="transform opacity-100 scale-100"
            leave-active-class="transition ease-in duration-75"
            leave-from-class="transform opacity-100 scale-100"
            leave-to-class="transform opacity-0 scale-95"
        >
            <MenuItems
                class="absolute right-0 z-100 mt-2 w-40 origin-top-right rounded-md bg-white shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none"
            >
                <div class="py-1">
                    <MenuItem
                        v-for="(option, index) in sortOptions"
                        :key="index"
                    >
                        <button
                            :class="[
                                currentSortIndex === index
                                    ? 'font-bold'
                                    : 'hover:font-medium',
                                'block w-full px-4 py-2 text-left text-sm ',
                            ]"
                            @click="updateSort(index)"
                        >
                            {{ t(option.translate_key, option.name) }}
                        </button>
                    </MenuItem>
                </div>
            </MenuItems>
        </transition>
    </Menu>
</template>
<script setup lang="ts">
import {
    AdjustmentsHorizontalIcon,
    Bars3BottomLeftIcon,
} from '@heroicons/vue/20/solid'
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import type { PropType } from 'vue'

const props = defineProps({
    query: {
        type: Object as PropType<Record<string, string | number>>,
        default: () => {},
    },
})

const inventoryStore = useInventoryStore()
const inventoryFilterStore = useInventoryFilterStore()
const { t } = useI18n()

const currentSortIndex = computed(() => {
    if (!props.query.sort) {
        return 0
    }

    const findIndex = sortOptions.value.findIndex(
        (item) =>
            item.sort === inventoryStore.sort?.sort &&
            item.sort_direction === inventoryStore.sort?.sortDirection,
    )

    return findIndex > -1 ? findIndex : 0
})

const sortOptions = computed(() => inventoryStore.getFilteredSortOptions)
const currentSort = computed(() => inventoryStore.getCurrentSortOption)

function updateSort(index: number) {
    const option = sortOptions.value[index]

    inventoryStore.updateSort(
        option.sort === null
            ? null
            : {
                  sort: option.sort,
                  sortDirection: option.sort_direction ?? null,
              },
    )
}
</script>
