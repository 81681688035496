<template>
    <div class="p-6">
        <ClientOnly>
            <div class="flex items-center">
                <div class="grid grid-cols-2 gap-x-2 gap-y-6">
                    <div class="col-span-2 mt-1">
                        <Vueform
                            ref="form$"
                            v-model="formValues"
                            :endpoint="false"
                            sync
                        >
                            <div class="col-span-6 mt-1">
                                <RangeInput
                                    v-model="formValues.min"
                                    type="min"
                                    :prefix="options.prefix"
                                    :suffix="options.suffix"
                                    :disable-format="options.disableFormat"
                                    @update="
                                        updateFromInputValue(
                                            $event[0],
                                            $event[1],
                                        )
                                    "
                                />
                            </div>

                            <div class="col-span-6 mt-1">
                                <RangeInput
                                    v-model="formValues.max"
                                    type="max"
                                    :prefix="options.prefix"
                                    :suffix="options.suffix"
                                    :disable-format="options.disableFormat"
                                    @update="
                                        updateFromInputValue(
                                            $event[0],
                                            $event[1],
                                        )
                                    "
                                />
                            </div>
                        </Vueform>
                    </div>

                    <div class="col-span-2 mt-1 pl-4 pr-2 sm:col-span-2">
                        <Vueform v-model="rangeValues" sync>
                            <SliderElement
                                name="slider"
                                show-tooltip="focus"
                                :default="rangeValues.slider"
                                :min="ranges.min"
                                :max="ranges.max"
                                :step="options.increment"
                                :format="{
                                    prefix: options.prefix,
                                    suffix: options.suffix,
                                    thousand: !options.disableFormat
                                        ? ','
                                        : undefined,
                                    decimals: 0,
                                }"
                                @change="update"
                            />
                        </Vueform>
                    </div>
                </div>
            </div>
        </ClientOnly>
    </div>
</template>

<script setup lang="ts">
import type { PropType } from 'vue'
import type { FilterOption, RangeOptions } from '~/utils/types/inventoryFilter'
import RangeInput from '~/components/SearchResultsPage/SearchResultsFilter/Components/Inputs/RangeInput.vue'
import { formatMaskedValue } from '~/utils/functions'

const filterStore = useInventoryFilterStore()

const props = defineProps({
    filter: {
        type: Object as PropType<FilterOption>,
        default: () => {},
    },
    resetFilter: {
        type: Object as PropType<Record<string, any> | null>,
        default: null,
    },
})
const emit = defineEmits(['update'])

const form$ = ref()
const filtersResetting = computed(() => filterStore.filtersResetting)

onMounted(() => updateInitialValues())

watch(
    () => filtersResetting.value,
    (shouldReset) => {
        if (shouldReset) {
            resetRanges()
        }
    },
)

watch(
    () => filterStore.filtersComponentFullResetId,
    (id) => {
        if (id !== null && id === props.filter.id) {
            resetRanges()
        }
    },
)

// account for facets load
watch(
    () => props.filter.range,
    () => updateInitialValues(),
)

watch(
    () => props.resetFilter,
    (filter) => {
        if (filter !== null && props.filter.id === filter.range) {
            resetRanges(true)
        }
    },
)

function updateInitialValues() {
    const min = defaultMin.value
    const max = defaultMax.value

    rangeValues.value.slider[0] = min
    rangeValues.value.slider[1] = max

    formValues.value.min = min
    formValues.value.max = max

    trackForm.value = {
        min,
        max,
    }
}

const options = computed(() => {
    const rangeOptions: RangeOptions = props.filter.rangeOptions ?? {}

    return {
        ...rangeOptions,
        ...{
            increment: rangeOptions.increment ?? 500,
            disableFormat: rangeOptions.disableFormat ?? false,
        },
    }
})

const ranges = computed(() => props.filter.range ?? { min: 0, max: 0 })
const defaultValues = computed(() =>
    props.filter.default?.toLowerCase().split(' to '),
)

const defaultMin = computed(() =>
    defaultValues.value && parseInt(defaultValues.value[0]) !== ranges.value.min
        ? parseInt(defaultValues.value[0])
        : ranges.value.min,
)

const defaultMax = computed(() =>
    defaultValues.value && parseInt(defaultValues.value[1]) !== ranges.value.max
        ? parseInt(defaultValues.value[1])
        : ranges.value.max,
)

const rangeValues = ref({
    slider: [defaultMin.value, defaultMax.value],
})

const formValues = ref({
    min: defaultMin.value as string | number,
    max: defaultMax.value as string | number,
})

const trackForm = ref()

function update(event: any) {
    const min: number = formatMaskedValue(event[0])
    const max: number = formatMaskedValue(event[1])

    if (min !== formValues.value.min || max !== formValues.value.max) {
        emitUpdates(min, max)

        form$.value.el$('min').update(min.toString())
        form$.value.el$('max').update(max.toString())
    }
}

function updateFromInputValue(event: any, type: RangeType) {
    const newValue = formatMaskedValue(event.model)
    const min = formatMaskedValue(formValues.value?.min)
    const max = formatMaskedValue(formValues.value?.max)

    if (isNaN(newValue) || (min && max && min > max)) {
        formValues.value[type] = trackForm.value[type]
        return
    } else if (min && min < ranges.value.min) {
        formValues.value.min = ranges.value.min
        return
    } else if (max && max > ranges.value.max) {
        formValues.value.max = ranges.value.max
        return
    }

    // only update if the input has changed
    if (trackForm.value[type] !== newValue) {
        emitUpdates(
            formValues.value.min
                ? formatMaskedValue(formValues.value.min.toString())
                : props.filter.range?.min,
            formValues.value.max
                ? formatMaskedValue(formValues.value.max.toString())
                : props.filter.range?.max,
        )

        trackForm.value[type] = newValue

        // update range
        updateSlider({
            [type]: newValue.toString(),
        })
    }
}

function emitUpdates(min: any, max: any) {
    min = parseInt(min)
    max = parseInt(max)

    emit('update', {
        filter: props.filter.id,
        title: props.filter.name,
        type: props.filter.type,
        value:
            ranges.value.min === min && ranges.value.max === max
                ? null
                : { min, max },
    })
}

function updateSlider(data: SliderUpdate) {
    if (data.min && data.min !== '') {
        rangeValues.value.slider[0] = parseInt(data.min)
    }

    if (data.max && data.max !== '') {
        rangeValues.value.slider[1] = parseInt(data.max)
    }
}

function resetRanges(single: boolean = false) {
    const min = ranges.value.min
    const max = ranges.value.max

    if (formValues.value.min !== min || formValues.value.max !== max) {
        formValues.value.min = min
        formValues.value.max = max
        trackForm.value.min = min
        trackForm.value.max = max

        form$.value.el$('min').update(min.toString())
        form$.value.el$('max').update(max.toString())

        rangeValues.value = {
            slider: [min, max],
        }

        updateSlider({
            min: min.toString(),
            max: max.toString(),
        })

        if (single) {
            emitUpdates(min, max)
        }
    }
}

type RangeType = 'min' | 'max'

interface SliderUpdate {
    min?: string
    max?: string
}
</script>
