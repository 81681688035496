<template>
    <!-- max-w-wide -->
    <div class="mx-auto max-w-wide mb-8 min-h-full">
        <!-- lg:space-x-8 lg:px-8 -->
        <div
            :class="{
                'inventory-standard-container': !searchStore.getIsAiMode,
            }"
            class="flex lg:relative"
        >
            <FilterComponent
                :filters="filters"
                :pills="pills"
                :filter-counts="filterCounts"
                :query="pageQuery"
                :reset-filter="resetFilter"
                @update="filterChanged"
                @reset-filters="resetFilters"
                @remove-filter="removeSingleFilter"
            />
            <main
                :class="{ 'inventory-ai-container': searchStore.getIsAiMode }"
                class="bg-gray-100 transition duration-300 w-full"
            >
                <InventoryResults
                    :inventory="vehicles"
                    :meta="meta"
                    :pills="pills"
                    :query="pageQuery"
                    :category="category"
                    :merchandising="merchandising"
                    :default-banners="defaultBanners"
                    :is-first-page="isFirstPage"
                    @remove-filter="removeSingleFilter"
                    @query-updated="queryUpdated"
                />
                <Pagination :meta="meta" @paginate="paginate" />
                <SearchResultsDisclaimer v-if="domainStore.payments.enabled" />
            </main>
        </div>

        <!--        <Featured /> -->
        <Teleport to="body">
            <SearchResultsFilterSlideOver>
                <FilterComponent
                    :is-mobile="true"
                    :filters="filters"
                    :pills="pills"
                    :filter-counts="filterCounts"
                    :query="pageQuery"
                    :reset-filter="resetFilter"
                    @update="filterChanged"
                    @reset-filters="resetFilters"
                    @remove-filter="removeSingleFilter"
                />
            </SearchResultsFilterSlideOver>
        </Teleport>
    </div>
</template>

<script setup lang="ts">
import type {
    Category,
    InventoryMerchandising,
    Vehicle,
    VehicleBodyStyle,
    VehicleMake,
} from '~/utils/types/inventory'
import type {
    Facet,
    FacetParam,
    FilterOption,
} from '~/utils/types/inventoryFilter'
import { fractionToDecimal } from '~/utils/functions'
import { trackAscPageViewEvent } from '~/composables/asc/events/Pageview'
import { throw404Error } from '~/composables/throw404Error'
import InventoryResults from '@/components/SearchResultsPage/SearchResults.vue'
import Pagination from '@/components/Shared/Pagination/index.vue'
import FilterComponent from '@/components/SearchResultsPage/SearchResultsFilter/Filters.vue'
import SimpleFilter from '@/components/SearchResultsPage/SearchResultsFilter/Components/Simple.vue'
import RangeFilter from '@/components/SearchResultsPage/SearchResultsFilter/Components/Range.vue'
import ParentChildFacet from '@/components/SearchResultsPage/SearchResultsFilter/Components/ParentChildFacet.vue'
import AttributeFacet from '@/components/SearchResultsPage/SearchResultsFilter/Components/AttributeFacet.vue'
import SearchResultsFilterSlideOver from '~/components/SearchResultsPage/SearchResultsFilter/SearchResultsFilterSlideOver.vue'
import { InsightEvent } from '~/utils/types/insights/enums'
import { trackElementConfigurationEvent } from '~/composables/asc/events/ElementConfiguration'
import type { Pill } from '~/stores/inventoryFilter'
import filterSchema from '~/utils/schemas/filterSchema'
import {
    ASCElementState,
    ASCElementSubType,
    ASCElementType,
    ASCEvent,
    ASCEventAction,
    ASCEventActionResult,
    ASCPageType,
} from '~/utils/types/asc/enums'
import type { ResourceMeta } from '~/utils/types/global'
import SearchResultsDisclaimer from '~/components/SearchResultsPage/SearchResultsDisclaimer.vue'
// import Featured from '@/components/SearchResultsPage/Featured.vue'

const route = useRoute()
const localePath = useLocalePath()
const inventoryStore = useInventoryStore()
const filterStore = useInventoryFilterStore()
const domainStore = useDomainStore()
const eventStore = useEventStore()
const searchStore = useSearchStore()

useHead({
    title: 'Inventory',
})

definePageMeta({
    // validate: (route) => {
    //     console.log(route)
    //
    //     return false
    // },
    key: (route) =>
        useGlobalStore().dynamicPageRedirectKey ? route.fullPath : route.path,
})

const { t } = useI18n()
const category = ref<Category | null>(null)
const categoryParam = (route.params.category as string | null) ?? null

if (categoryParam !== null) {
    category.value = await inventoryStore.getCategory(categoryParam)

    if (category.value === null) {
        throw404Error()
    }
}

const makeOrBodyStyleParam =
    (route.params.makeOrBodyStyle as string | null) ?? null

const routeMake = ref<VehicleMake | null>(null)
const routeBodyStyle = ref<VehicleBodyStyle | null>(null)

if (makeOrBodyStyleParam !== null) {
    const makes: VehicleMake[] = filterStore.globalFilters?.makes ?? []
    const bodyStyles: VehicleBodyStyle[] =
        filterStore.globalFilters?.bodyStyles ?? []

    // check makes first
    routeMake.value =
        makes.find(
            (make: VehicleMake) =>
                make.slug === makeOrBodyStyleParam.toLowerCase(),
        ) ?? null

    if (routeMake.value === null) {
        routeBodyStyle.value =
            bodyStyles.find(
                (bodyStyle: VehicleBodyStyle) =>
                    bodyStyle.slug === makeOrBodyStyleParam.toLowerCase(),
            ) ?? null

        if (routeBodyStyle.value === null) {
            throw404Error()
        }
    }
}

const vehicles = computed(() => inventoryStore.vehicles as Vehicle[])
const meta = computed(() => (inventoryStore.meta ?? {}) as ResourceMeta)
const merchandising = computed(
    () => (inventoryStore.merchandising ?? {}) as InventoryMerchandising,
)
const facets = computed(() => filterStore.facets)
const facetStats = computed(() => filterStore.facetStats)
const filterQuery = computed(() => filterStore.filters)
const computedSort = computed(() => inventoryStore.sort)

const pageQuery = ref<Record<string, any>>({
    ...{
        'make_model.lvl0': routeMake.value?.name ?? undefined,
        body_style: routeBodyStyle.value?.name ?? undefined,
    },
    ...route.query,
})

const resetFilter = ref<Record<string, any> | null>(null)
const defaultBanners = ref([])
const shouldWatchSort = ref(false)

watch(
    () => computedSort.value,
    (value) => {
        if (!shouldWatchSort.value) {
            shouldWatchSort.value = true
        } else {
            updateInventory(value)
        }
    },
)

watch(
    () => vehicles.value,
    () => sendInventoryLoadedEvent(),
)

watch(
    () => searchStore.searchMode,
    () => {
        inventoryStore.ignoreInventoryLoading = true
        resetFilters()
    },
)

// history change listener
onMounted(() => window.addEventListener('popstate', handleHistoryChange))
onUnmounted(() => window.removeEventListener('popstate', handleHistoryChange))

filterStore.assignFilters(pageQuery.value)

inventoryStore.fetchInventory(pageQuery.value, category.value, true)
filterStore.fetchFacets(pageQuery.value, category.value)

// type query check until we implement facet updates on every req
filterStore.typeQueryActive =
    pageQuery.value?.type === 'New' || pageQuery.value?.type === 'Used'

if (pageQuery.value.sort) {
    inventoryStore.updateSort({
        sort: pageQuery.value.sort,
        sortDirection: pageQuery.value.sort_direction ?? null,
    })
} else {
    shouldWatchSort.value = true
}

function queryUpdated(query: string) {
    filterStore.customQueryFilters.query = [query]
    updateInventory(computedSort.value)
}

function filterChanged(e: FacetParam) {
    if (e.type === 'query') {
        filterStore.removeCustomQueryFilter(e)
    } else if (e.type === 'facet') {
        filterStore.updateFacet(e)
    } else {
        filterStore.updateFilter(e)
    }

    updateInventory(computedSort.value)
    sendTrackingEvents(e)
}

function paginate(page: number) {
    updateInventory(computedSort.value, page)
}

function sendTrackingEvents(event: FacetParam) {
    trackElementConfigurationEvent({
        pageType: ASCPageType.ITEM_LIST,
        data: {
            event_action_result: ASCEventActionResult.CHECK,
            event_action: ASCEventAction.CLICK,
            element_state:
                event.type === 'filter' || event.value
                    ? ASCElementState.ACTIVE
                    : ASCElementState.INACTIVE,
            element_type: ASCElementType.ITEM_DETAILS,
            element_subtype: ASCElementSubType.SIDEBAR_MENU,
            element_title: event.filter,
            element_text: event.title,
            element_value:
                event.type === 'filter'
                    ? event.value
                        ? `${event.value.min}-${event.value.max}`
                        : null
                    : event.option,
        },
    })

    eventStore.sendInsightEvent(InsightEvent.SRP_FILTER_CLICKED, {
        filters: filterStore.buildQueryFromFilters(),
    })
}

const isFirstPage = computed<boolean>(() => meta.value?.current_page === 1)

const filters = computed<FilterOption[]>(() => {
    const returnArr: FilterOption[] = []

    const locationParentFacetLength = (
        facets.value['location_search_term.lvl0'] ?? []
    ).length

    const bedLengthParentFacetLength = (
        facets.value['bed_length_group.lvl0'] ?? []
    ).length

    if (locationParentFacetLength > 1) {
        returnArr.push({
            id: 'location_search_term.lvl0',
            childId: 'location_search_term.lvl1',
            name: t('location'),
            type: 'facet',
            options: facets.value['location_search_term.lvl0'],
            component: ParentChildFacet,
            open: !!pageQuery.value['location_search_term.lvl0'],
            children: facets.value['location_search_term.lvl1'],
            childFormat: (val: string | null) => val?.replace('_', ', '),
        })
    } else {
        returnArr.push({
            id: 'location_search_term.lvl1',
            name: t('location'),
            type: 'facet',
            options: (facets.value['location_search_term.lvl1'] || []).map(
                (item: Record<string, any>) => {
                    item.label = formatSearchTerm(item.value)
                    return item
                },
            ),
            component: SimpleFilter,
            open: !!pageQuery.value['location_search_term.lvl1'],
            shouldHide:
                (facets.value['location_search_term.lvl1'] || []).length <= 1,
        })
    }

    return filterHiddenFacets([
        ...returnArr,
        ...[
            {
                id: 'condition',
                name: t('condition'),
                type: 'facet',
                options: facets.value.condition,
                component: SimpleFilter,
                open: !!pageQuery.value.condition,
                shouldHide: (facets.value.condition || []).length <= 1,
            },
            // {
            //     id: 'type',
            //     name: t('srp_filter_new_used'),
            //     type: 'facet',
            //     options: facets.value.type,
            //     component: SimpleFilter,
            //     open: !!pageQuery.value.type,
            //     shouldHide: (facets.value.type || []).length <= 1,
            // },
            {
                id: 'make_model.lvl0',
                childId: 'make_model.lvl1',
                name: t('srp_filter_make_model'),
                type: 'facet',
                options: facets.value['make_model.lvl0'],
                component: ParentChildFacet,
                open: !!pageQuery.value['make_model.lvl0'],
                children: facets.value['make_model.lvl1'],
                // showMoreAmount: 10,
            },
            {
                id: 'price.selling',
                name: t('price'),
                type: 'filter',
                component: RangeFilter,
                open: !!filterQuery.value['price.selling'],
                default: filterQuery.value['price.selling'],
                range: checkForRangeMinMaxFacetDefaults(
                    0,
                    200000,
                    'price.selling',
                    10000,
                ),
                rangeOptions: {
                    prefix: '$',
                },
                shouldHide: domainStore.shouldHidePrice,
            },
            {
                id: 'body_style',
                name: t('body_style'),
                type: 'facet',
                options: facets.value.body_style,
                component: SimpleFilter,
                open: !!pageQuery.value.body_style,
            },
            {
                id: 'miles',
                name: t('miles'),
                type: 'filter',
                component: RangeFilter,
                open: !!filterQuery.value.miles,
                default: filterQuery.value.miles,
                range: checkForRangeMinMaxFacetDefaults(
                    0,
                    300000,
                    'miles',
                    10000,
                ),
                rangeOptions: {
                    suffix: 'mi',
                    increment: 1000,
                },
            },
            {
                id: 'fuel',
                name: t('fuel_type'),
                type: 'facet',
                options: facets.value.fuel,
                component: SimpleFilter,
                open: !!pageQuery.value.fuel,
            },
            {
                id: 'featured_options.lvl1',
                name: t('features'),
                type: 'facet',
                options: (facets.value['featured_options.lvl1'] || []).map(
                    (item: Record<string, any>) => {
                        item.label = formatSearchTerm(item.value)
                        return item
                    },
                ),
                categories: facets.value['featured_options.lvl0'],
                component: SimpleFilter,
                open: !!pageQuery.value['featured_options.lvl1'],
                search: true,
            },
            {
                id: 'transmission_type',
                name: t('transmission'),
                type: 'facet',
                options: facets.value.transmission_type,
                component: SimpleFilter,
                open: !!pageQuery.value.transmission_type,
            },
            {
                id: 'drivetrain',
                name: t('drive_type'),
                type: 'facet',
                options: facets.value.drivetrain,
                component: SimpleFilter,
                open: !!pageQuery.value.drivetrain,
            },
            {
                id: 'tonnage',
                name: t('tonnage'),
                type: 'facet',
                options: sortedTonnageOptions.value,
                component: SimpleFilter,
                open: !!pageQuery.value.tonnage,
                shouldHide: (sortedTonnageOptions.value?.length ?? []) === 0,
            },
            {
                id: 'bed_length_group.lvl0',
                childId: 'bed_length_group.lvl1',
                name: t('bed_length'),
                type: 'facet',
                options: bedLengthOptionsReversed.value,
                component: ParentChildFacet,
                open: !!pageQuery.value['bed_length_group.lvl0'],
                children: facets.value['bed_length_group.lvl1'],
                childFormat: (val: string) => formatBedLengthChild(val),
                shouldHide: bedLengthParentFacetLength === 0,
            },
            {
                id: 'lifted',
                name: t('lifted'),
                type: 'facet',
                options: liftedFacetOptions.value,
                component: SimpleFilter,
                open: !!pageQuery.value.lifted,
                shouldHide:
                    liftedFacetOptions.value.length === 2 &&
                    (liftedFacetOptions.value[0].count === 0 ||
                        liftedFacetOptions.value[1].count === 0),
            },
            {
                id: 'exterior_color_parent',
                name: t('exterior_color'),
                type: 'facet',
                options: facets.value.exterior_color_parent,
                component: SimpleFilter,
                open: !!pageQuery.value.exterior_color_parent,
            },
            {
                id: 'year',
                name: t('year'),
                type: 'filter',
                component: RangeFilter,
                open: !!filterQuery.value.year,
                default: filterQuery.value.year,
                range: checkForRangeMinMaxFacetDefaults(
                    1940,
                    new Date().getFullYear() + 1,
                    'year',
                ),
                rangeOptions: {
                    increment: 1,
                    disableFormat: true,
                },
            },
            // {
            //     id: 'category',
            //     name: 'Category',
            //     options: [
            //         {
            //             value: 'new-arrivals',
            //             label: 'New Arrivals',
            //             checked: false,
            //         },
            //         { value: 'trucks', label: 'Trucks', checked: true },
            //     ],
            //     component: '',
            // },
        ],
        ...groupedAttributeFacets.value.map((attributeFacet) => ({
            id: attributeFacet.key,
            name: t(
                `srp_filter_attribute_${attributeFacet.key.replace('-', '_')}`,
                attributeFacet.category,
            ),
            type: 'facet',
            options: attributeFacet.classes,
            component: AttributeFacet,
            open: attributeCategoryOpen(attributeFacet.key),
        })),
    ])
})

function filterHiddenFacets(filters: any[]): FilterOption[] {
    return hiddenFacets.value.length > 0
        ? filters.filter(
              (filter: any) => !hiddenFacets.value.includes(filter.id),
          )
        : filters
}

const filterCounts = computed<Record<string, any>>(() => {
    const returnObj: Record<string, any> = {
        total: 0,
    }

    if (pageQuery.value.search) {
        returnObj.search = 1
        returnObj.total++
    }

    filters.value.forEach((filter: FilterOption) => {
        if (filter.type === 'facet') {
            if (filter.options) {
                filter.options?.forEach((option: Facet) => {
                    if (option.checked) {
                        if (!returnObj[filter.id]) {
                            returnObj[filter.id] = 1
                        } else {
                            returnObj[filter.id]++
                        }

                        returnObj.total++
                    }

                    const children = option.children ?? option.values

                    // make parent/child and attribute facet counts
                    children?.forEach((child: Facet) => {
                        if (child.checked) {
                            if (!returnObj[filter.id]) {
                                returnObj[filter.id] = 1
                            } else {
                                returnObj[filter.id]++
                            }

                            returnObj.total++
                        }
                    })
                })
            }
        } else if (filterQuery.value[filter.id]) {
            returnObj[filter.id] = 1
            returnObj.total++
        }
    })

    return returnObj
})

const pills = computed<Pill[]>(() => {
    const filterPills: Pill[] = []
    const query: Record<string, string | number> = pageQuery.value

    Object.keys(query).forEach((key: string) => {
        const schema = filterSchema[key]

        if (schema && query[key]) {
            if (schema.range) {
                const value = query[key]?.toString()
                const filter = filters.value.find(
                    (filter: FilterOption) => filter.id === schema.range,
                )

                filterPills.push({
                    key,
                    value: schema.value(value),
                    name: schema.format(value, filter?.range),
                    type: 'filter',
                    range: schema.range,
                })
            } else {
                query[key]
                    ?.toString()
                    .split(',')
                    .forEach((value: string) => {
                        const schemaValue = schema.value(value)

                        filterPills.push({
                            key,
                            value: schemaValue,
                            name: schema.format
                                ? schema.format(value)
                                : schemaValue,
                            type: schema.query ? 'query' : 'facet',
                        })
                    })
            }
        } else if (key.startsWith('attribute.')) {
            const attributeArr = key.split('.')

            if (attributeArr.length === 3) {
                const category = ucwords(attributeArr[1].replace('-', ' '))
                const className = ucwords(attributeArr[2].replace('-', ' '))

                query[key]
                    ?.toString()
                    .split(',')
                    .forEach((value: string) => {
                        filterPills.push({
                            key,
                            value,
                            name: `${category} ${className}: ${value}`,
                            type: 'facet',
                            category: attributeArr[1],
                        })
                    })
            }
        }
    })

    return filterPills
})

const bedLengthOptionsReversed = computed(() =>
    [...(facets.value['bed_length_group.lvl0'] ?? [])].reverse(),
)

const attributeFacets = computed(() => {
    const returnArr: any[] = []

    Object.keys(facets.value).forEach((key) => {
        if (key.startsWith('attribute.')) {
            const facet = facets.value[key]
            const attributeNames = getAttributeNames(key)

            returnArr.push({
                key,
                categorySlug: attributeNames.category,
                category: ucwords(attributeNames.category.replace('-', ' ')),
                class: ucwords(attributeNames.class.replace('-', ' ')),
                values: facet,
            })
        }
    })

    return returnArr
})

const groupedAttributeFacets = computed<any[]>(() => {
    const returnArr: any[] = []

    attributeFacets.value.forEach((facet) => {
        const indexSearch = returnArr.findIndex(
            (item) => item.category === facet.category,
        )

        if (indexSearch === -1) {
            returnArr.push({
                category: facet.category,
                key: facet.categorySlug,
                classes: [facet],
            })
        } else {
            returnArr[indexSearch].classes.push(facet)
        }
    })

    return returnArr
})

const liftedFacetOptions = computed<any[]>(() => {
    const liftedObj = facets.value.lifted?.find((item) => item.value === 'true')
    const notLiftedObj = facets.value.lifted?.find(
        (item) => item.value === 'false',
    )

    return [
        {
            checked: liftedObj?.checked ?? false,
            count: liftedObj?.count ?? 0,
            value: 'true',
            label: 'Yes',
        },
        {
            checked: notLiftedObj?.checked ?? false,
            count: notLiftedObj?.count ?? 0,
            value: 'false',
            label: 'No',
        },
    ]
})

const sortedTonnageOptions = computed(() => {
    if (!facets.value.tonnage) {
        return facets.value.tonnage
    }

    return JSON.parse(JSON.stringify(facets.value.tonnage))
        .filter((tonnage: any) => tonnage.value !== '1/4 Ton') // remove 1/4 ton
        .sort(
            (a: any, b: any) =>
                fractionToDecimal(a.value.replace(' Ton', '')) -
                fractionToDecimal(b.value.replace(' Ton', '')),
        )
})

const hiddenFacets = computed(
    () => getSetting(domainStore.settings, 'inventory.srp.facets.hidden') ?? [],
)

function resetFilters() {
    filterStore.filtersResetting = true
    filterStore.reset()

    updateInventory(computedSort.value)

    nextTick(() => (filterStore.filtersResetting = false))
}

function updateInventory(
    sortObj: Record<string, any> | null = null,
    page: number | null = null,
) {
    const query: Record<string, any> = filterStore.buildQueryFromFilters()

    if (sortObj) {
        query.sort = sortObj.sort

        if (sortObj.sortDirection) {
            query.sort_direction = sortObj.sortDirection
        }
    } else {
        delete query.sort
        delete query.sort_direction
    }

    if (page) {
        query.page = page
    }

    inventoryStore.fetchInventory(query, category.value)

    // type query check until we implement facet updates on every req
    const typeQueryFound: boolean =
        query?.type === 'New' || query?.type === 'Used'

    if (
        (!filterStore.typeQueryActive && typeQueryFound) ||
        (filterStore.typeQueryActive && !typeQueryFound)
    ) {
        filterStore.fetchFacets(query, category.value)
        filterStore.typeQueryActive =
            !filterStore.typeQueryActive && typeQueryFound
    }

    // TODO: Add this back when facets are working
    // filterStore.fetchFacets(query, props.category)

    // update page query for pagination
    pageQuery.value = query

    navigateTo({
        path: localePath(
            category.value ? `/${category.value.slug}` : '/inventory',
        ),
        query: pageQuery.value,
    })
}

function checkForRangeMinMaxFacetDefaults(
    fallbackMin: number | string,
    fallbackMax: number | string,
    facet: string,
    round: number | null = null,
) {
    // if a max is set to zero, something is wrong with the data, so use the fallbacks
    if (!facetStats.value[facet] || facetStats.value[facet].max === 0) {
        return { min: fallbackMin, max: fallbackMax }
    }

    let min = parseInt(facetStats.value[facet].min.toString())
    let max = parseInt(facetStats.value[facet].max.toString())

    if (round !== null) {
        min = Math.floor(min / round) * round
        max = Math.ceil(max / round) * round
    }

    return {
        min,
        max,
    }
}

function getAttributeNames(key: string) {
    const keySplit = key.replace('attribute.', '').split('.')

    return {
        category: keySplit[0],
        class: keySplit[1],
    }
}

function attributeCategoryOpen(key: string) {
    return (
        Object.keys(pageQuery.value).find((queryKey) =>
            queryKey.includes(`attribute.${key}`),
        ) !== undefined
    )
}

function formatBedLengthChild(val: string) {
    let min, max

    if (val.includes('+')) {
        min = parseInt(val)
        max = null
    } else {
        const splitArr = val.split('-')

        min = parseInt(splitArr[0])
        max = parseInt(splitArr[1])
    }

    const minFt = Math.floor(min / 12)
    const minIn = min % 12

    let returnStr = `${minFt}'`

    if (minIn > 0) {
        returnStr += `${minIn}"`
    }

    if (max === null) {
        returnStr += '+'
    } else {
        const maxFt = Math.floor(max / 12)
        const maxIn = max % 12

        returnStr += `-${maxFt}'`

        if (maxIn > 0) {
            returnStr += `${maxIn}"`
        }
    }

    return returnStr
}

function removeSingleFilter(e: Record<string, any>) {
    resetFilter.value = {
        filter: e.key,
        option: e.value,
        type: e.type,
        range: e.range ?? null,
        category: e.category ?? null,
        value: false,
    } as FacetParam

    nextTick(() => (resetFilter.value = null))
}

function sendInventoryLoadedEvent() {
    trackAscPageViewEvent({
        vehicles: vehicles.value,
        optionalEvent: ASCEvent.ITEMLIST_PAGEVIEW,
    })
}

function handleHistoryChange() {
    pageQuery.value = Object.fromEntries(
        new URLSearchParams(window.location.search).entries(),
    )

    filterStore.assignFilters(pageQuery.value)
    filterStore.syncFacetsFromQuery(pageQuery.value)
    inventoryStore.fetchInventory(pageQuery.value, category.value)

    // force filter sync
    filterStore.filtersSyncing = true
    nextTick(() => (filterStore.filtersSyncing = false))
}
</script>

<style scoped>
.inventory-ai-container {
    @apply absolute px-4 sm:px-6 lg:px-8;
}

.inventory-standard-container {
    @apply relative px-4 sm:px-6 lg:px-8;
}
</style>
