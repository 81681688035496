<template>
    <div class="flex flex-col gap-y-4 lg:pt-4">
        <div class="hidden lg:block">
            <button
                class="inline-flex items-center gap-x-1.5 rounded-md bg-white border border-gray-200 px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                @click="searchStore.toggleSearchMode()"
            >
                <ChevronLeftIcon class="-ml-0.5 size-5" aria-hidden="true" />
                Back
            </button>
        </div>

        <div class="relative text-gray-600 w-full rounded-xl shadow lg:w-1/2">
            <input
                ref="searchRef"
                v-model="search"
                class="border border-gray-200 bg-white px-5 pr-16 rounded-xl text-sm focus:outline-none w-full h-16"
                type="search"
                name="search"
                :placeholder="t('search_ai_placeholder')"
                @keydown.enter="getInventory"
            />
            <button
                type="button"
                class="absolute right-0 top-0 mt-3.5 mr-3 inline-flex items-center gap-x-1.5 rounded-lg bg-gradient-to-t from-gray-800 to-gray-700 hover:from-gray-700 hover:to-gray-600 px-2 py-2 text-sm font-semibold text-white shadow-md hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                @click="getInventory"
            >
                <span class="hidden sm:block mr-0.5">Search with AI</span>
                <SparklesIcon class="size-5" aria-hidden="true" />
            </button>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ChevronLeftIcon, SparklesIcon } from '@heroicons/vue/24/solid'
import { ref } from 'vue'

const searchStore = useSearchStore()
const inventoryStore = useInventoryStore()

const search = ref('')
const searchRef = ref<HTMLDivElement>()

const { t } = useI18n()

async function getInventory() {
    searchRef.value?.blur()

    await inventoryStore.fetchInventory({
        query: search.value,
    })
}
</script>
