<template>
    <!--         v-if="!searchStore.getIsAiMode" -->
    <div
        class="relative cursor-pointer"
        @click="searchStore.toggleSearchMode()"
    >
        <div
            class="w-full h-20 flex items-center border border-gray-200 shadow-lg bg-white rounded-3xl p-2 duration-300 ease-in-out"
        >
            <div
                class="bg-gradient-to-t from-gray-800 to-gray-700 w-1/2 h-16 rounded-2xl shadow-md transform duration-300 ease-in-out"
                :class="{ 'translate-x-full': searchStore.getIsAiMode }"
            ></div>
        </div>
        <div class="absolute inset-0 flex justify-between">
            <div class="w-1/2 ml-2 flex flex-row justify-center items-center">
                <div
                    class="tracking-tight flex items-center font-medium"
                    :class="{ active: !searchStore.getIsAiMode }"
                >
                    <AdjustmentsHorizontalIcon class="h-5 w-5 mr-2" />
                    <span>Filter</span>
                </div>
            </div>
            <div class="w-1/2 mr-2 flex flex-row justify-center items-center">
                <div
                    class="tracking-tight flex items-center font-medium"
                    :class="{ active: searchStore.getIsAiMode }"
                >
                    <SparklesIcon class="h-5 w-5 mr-2" />
                    <span>AI Search</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import {
    AdjustmentsHorizontalIcon,
    SparklesIcon,
} from '@heroicons/vue/24/solid'

const searchStore = useSearchStore()
</script>

<style>
.active {
    @apply text-white hover:text-gray-200;
}
</style>
